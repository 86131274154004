import React, { Fragment } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { Input, FormFeedback } from 'reactstrap'
import { fromJS } from 'immutable'
import _ from 'lodash'
import Button from 'components/Button'
import AccountSelector from 'components/AccountSelector'
import FormLabel from 'components/FormLabel'
import ImportanceWidget from 'components/ImportanceWidget'
import Breadcrumb, { BreadcrumbItem } from 'components/Breadcrumb'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import CenterContent from 'components/CenterContent'
import SwimlanePreview from './SwimlanePreview'
import Icon from 'components/Icon'
import { PlainCheckbox } from 'components/Checkbox'
import {
  selectSwimlanes,
  selectAccounts,
  selectTags,
} from 'containers/SonraiData/selectors'
import CloudBadge from 'components/CloudBadge'
import TextLink from 'components/TextLink'
import SectionHeader from 'components/SectionHeader'
import { fetchTags } from 'containers/SonraiData/actions'
import MultiValueInput from 'components/MultiValueInput'
import { ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap'
import {
  createSwimlane,
  getPreviewResults,
  getTagValues,
  getBotValues,
  setManagementError,
} from './actions'
import {
  selectCreatingSwimlane,
  selectPreviewResults,
  selectPreviewResultsLoading,
  selectIsLoadingTagValues,
  selectTagValues,
  selectBotValues,
  selectError,
} from './selectors'
import SwimlaneTagManager from './SwimlaneTagManager'
import AsyncTypeahead from 'components/AsyncTypeahead'
import {
  exists,
  getAcceptableCloudAccountTypesForBots,
} from 'utils/sonraiUtils'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

export class CreateEditForm extends React.Component {
  constructor(props) {
    super(props)

    if (props.tags.isEmpty()) {
      props.fetchTags()
    }
    this.state = {
      swimlaneTitle: '',
      swimlaneDescription: '',
      swimlaneImportance: 5,
      swimlaneTags: [],
      swimlaneNames: [],
      swimlaneResourceIds: [],
      swimlaneAccounts: [],
      swimlanePreventionEnabled: false,
      showPreview: false,
      advanced: true,
      swimlaneBots: [],
      shouldClose: false,
      errorMsg: null,
    }

    this.styles = {
      submit: {
        margin: '1em 0',
        float: 'right',
      },
      modalBody: {
        maxHeight: '70vh',
        overflow: 'auto',
      },
      modalBodyPreview: {
        maxHeight: '70vh',
        overflow: 'hidden',
      },
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.error && !prevState.errorMsg) {
      this.setError(this.props.error)
    }
    // after load is complete with no errors, close em up
    if (!this.props.creatingSwimlane && prevState.shouldClose) {
      this.props.cancel()
    }
  }

  setError = msg => {
    this.setState({
      errorMsg: msg,
      showPreview: false,
      shouldClose: false,
    })
    this.props.setManagementError(null)
    setTimeout(() => {
      this.setState({ errorMsg: null })
    }, 7000)
  }

  closePreview = () => {
    this.setState({
      showPreview: false,
    })
  }

  setSwimlaneImportance = ({ importance }) => {
    this.setState({
      swimlaneImportance: importance,
    })
  }

  setPreventionEnabled = toggle => {
    this.setState({ swimlanePreventionEnabled: toggle })
  }

  setSwimlaneTitle = e => {
    this.setState({
      swimlaneTitle: e.target.value,
    })
  }

  setSwimlaneDescription = e => {
    this.setState({
      swimlaneDescription: e.target.value,
    })
  }

  setSwimlaneTags = newTagsVal => {
    if (!newTagsVal) {
      this.setState({
        swimlaneTags: [],
      })
    } else {
      const { value } = newTagsVal
      if (Array.isArray(value)) {
        this.setState({
          swimlaneTags: value,
        })
      } else {
        let arr = [...this.state.swimlaneTags]

        const { value, key, toRemove } = newTagsVal
        const combineStr = key !== '' || value !== '' ? `${key}:${value}` : key

        if (toRemove) {
          const index = arr.findIndex(tag => tag.includes(combineStr))
          arr.splice(index, 1)
          this.setState({ swimlaneTags: arr })
        } else {
          this.setState(state => ({
            swimlaneTags: [...state.swimlaneTags, combineStr],
          }))
        }
      }
    }
  }

  setSwimlaneAccounts = newAccountsVal => {
    this.setState({
      swimlaneAccounts: newAccountsVal,
    })
  }

  setSwimlaneBots = newBotsVal => {
    if (!newBotsVal) {
      this.setState({
        swimlaneBots: [],
      })
    } else {
      this.setState({
        swimlaneBots: newBotsVal,
      })
    }
  }

  setSwimlaneNames = values => {
    this.setState({
      swimlaneNames: values,
    })
  }

  setSwimlaneResourceIds = values => {
    this.setState({
      swimlaneResourceIds: values,
    })
  }

  doCreate = () => {
    this.setState({ errorMsg: null, shouldClose: true }, () => {
      this.props.createSwimlane({
        bots: this.state.swimlaneBots,
        swimlane: {
          description: this.state.swimlaneDescription,
          defaultImportance: this.state.swimlaneImportance,
          title: this.state.swimlaneTitle.trim(),
          names: this.state.swimlaneNames,
          resourceIds: this.state.swimlaneResourceIds,
          tags: this.state.swimlaneTags,
          accounts: this.state.swimlaneAccounts,
          preventionEnabled: this.state.swimlanePreventionEnabled,
        },
      })
    })
  }

  isDisabled = () => {
    const { swimlaneTitle } = this.state
    if (swimlaneTitle.trim() === '') {
      return true
    }

    return false
  }

  getTagOptions = () => {
    const serverTags = this.props.tags
      .filter(tag => !!tag.get('key'))
      .sortBy(tag => tag.get('key').toLowerCase())
      .toJS()
      .map(tag => ({ value: tag.key, label: tag.key }))

    const additionalOptions = this.state.swimlaneTags.map(tagStr => ({
      value: tagStr,
      label: tagStr,
    }))

    return serverTags.concat(additionalOptions)
  }

  preview = () => {
    this.props.getPreviewResults({
      importance: this.state.swimlaneImportance,
      tags: this.state.swimlaneTags,
      resourceNames: this.state.swimlaneNames,
      resourceIds: this.state.swimlaneResourceIds,
      accounts: this.state.swimlaneAccounts,
    })
    this.setState({
      showPreview: true,
    })
  }

  getHeaderLabel = () => {
    let label = this.props.editingSwimlane ? 'Swimlane ' : 'New Swimlane '
    label += this.state.showPreview ? ' Preview' : ' Details'
    return label
  }

  getBotOptions = () => {
    const accountTypes = getAcceptableCloudAccountTypesForBots(
      fromJS(this.state.swimlaneAccounts),
      this.props.accounts
    )
    return this.props.botValues
      .toJS()
      .filter(item => item)
      .filter(bot => {
        if (!_.isEmpty(accountTypes)) {
          if (accountTypes.includes(bot.cloud.toLowerCase())) {
            return true
          } else {
            return false
          }
        }
        return true
      })
      .map(bot => ({ label: bot.title, value: bot.srn }))
  }
  renderButtons = nameIsValid => {
    // Buttons should always be positive action on the right,
    // because people are use to things moving to the right as moving forward, and left as backwards
    if (this.props.creatingSwimlane) {
      return (
        <Button color="primary" disabled>
          <Icon fa name="sync" spin />
        </Button>
      )
    }

    const {
      swimlaneAccounts,
      swimlaneNames,
      swimlaneTags,
      swimlaneResourceIds,
    } = this.state

    const hasNoFilters =
      _.isEmpty(swimlaneAccounts) &&
      _.isEmpty(swimlaneNames) &&
      _.isEmpty(swimlaneTags) &&
      _.isEmpty(swimlaneResourceIds)

    return (
      <Fragment>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div>
            {!this.state.showPreview ? (
              <Button
                color="primary"
                onClick={this.preview}
                style={{ marginRight: '1em' }}
                disabled={this.isDisabled()}
              >
                Preview
              </Button>
            ) : (
              <Button
                color="primary"
                onClick={this.closePreview}
                style={{ marginRight: '1em' }}
              >
                <Icon fa name="caret-left" size="lg" />
              </Button>
            )}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!this.isDisabled() && nameIsValid && hasNoFilters && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon
                  fa
                  name="exclamation-circle"
                  color={this.props.theme.warn}
                />
                <span
                  style={{
                    color: this.props.theme.warn,
                    fontSize: '0.85rem',
                    margin: '0rem 1rem 0rem 0.5rem',
                  }}
                >
                  No resources will match this Swimlane unless at least one
                  filter is applied.
                </span>
              </div>
            )}
            <TextLink
              color="secondary"
              onClick={this.props.cancel}
              style={{ marginRight: '1em' }}
            >
              Cancel
            </TextLink>
            <Button
              color="primary"
              onClick={this.doCreate}
              disabled={!nameIsValid || this.isDisabled()}
            >
              Create
            </Button>
          </div>
        </div>
      </Fragment>
    )
  }

  isNameValid = () => {
    const { editingSwimlane, swimlanes } = this.props
    const title = this.state.swimlaneTitle.trim().toLowerCase()

    if (title === 'all' || /\s/g.test(title)) {
      return false
    }

    let filteredSwimlanes = swimlanes

    if (swimlanes) {
      if (editingSwimlane) {
        filteredSwimlanes = filteredSwimlanes.filter(
          sl => sl.get('srn') !== editingSwimlane.get('srn')
        )
      }

      if (
        filteredSwimlanes.find(
          swimlane => swimlane.get('title', '').toLowerCase() === title
        )
      ) {
        return false
      }
    }

    return true
  }

  clearError = () => {
    this.setState({ errorMsg: null })
  }

  handleBotsChange = params => {
    if (!exists(params)) {
      this.setState({ swimlaneBots: [] })
    } else {
      const selectedBots = this.getBotOptions().filter(bot =>
        params.value.includes(bot.value)
      )
      this.setState({
        swimlaneBots: selectedBots,
      })
    }
  }

  getBotBadge = botSrn => {
    const bot = this.props.botValues
      .toJS()
      .filter(item => item)
      .find(bot => bot.srn === botSrn)
    if (bot) {
      return (
        <span>
          <CloudBadge type={bot.cloud.toLowerCase()} />
          &nbsp;{bot.title}
        </span>
      )
    }
  }

  render() {
    const nameIsValid = this.isNameValid()
    const selectStyles = {
      menuList: styles => {
        return {
          ...styles,
          maxHeight: '175px',
        }
      },
    }

    return (
      <Fragment>
        <ModalHeader toggle={this.props.toggle}>
          Create New Swimlane
        </ModalHeader>
        <div style={{ width: '100%' }}>
          {this.state.errorMsg && (
            <Alert
              style={{ marginBottom: '1em', textAlign: 'center' }}
              color="danger"
              toggle={this.clearError}
            >
              {this.state.errorMsg}
            </Alert>
          )}
        </div>
        <ModalBody
          style={
            this.state.showPreview
              ? this.styles.modalBodyPreview
              : this.styles.modalBody
          }
        >
          {!this.state.showPreview ? (
            <div>
              <SectionHeader small>1. Swimlane Details</SectionHeader>
              <FormLabel required>Swimlane Name (Unique)</FormLabel>
              <Input
                invalid={!nameIsValid}
                value={this.state.swimlaneTitle}
                onChange={this.setSwimlaneTitle}
                disabled={this.props.creatingSwimlane}
              />
              {!nameIsValid && (
                <FormFeedback>
                  Swimlane names must be unique and cannot contain spaces
                </FormFeedback>
              )}
              <FormLabel>Description</FormLabel>
              <Input
                type="textarea"
                value={this.state.swimlaneDescription}
                onChange={this.setSwimlaneDescription}
                disabled={this.props.creatingSwimlane}
              />

              <FormLabel>
                Default Importance for Resources in Swimlane
              </FormLabel>
              <ImportanceWidget
                setImportance={this.setSwimlaneImportance}
                importance={this.state.swimlaneImportance}
                disabled={this.props.creatingSwimlane}
              />

              <SectionHeader small style={{ marginTop: '2em' }}>
                2. Swimlane Filters
              </SectionHeader>

              <div
                style={{
                  color: `rgb(86, 86, 86)`,
                  fontSize: '0.9rem',
                }}
              >
                The swimlane will be populated automatically with resources that
                match the filters below. First select one or many accounts as
                the primary filter. Leave blank to apply to all accounts
              </div>

              <FormLabel>Restricted to Accounts</FormLabel>
              <AccountSelector
                value={this.state.swimlaneAccounts.map(account => ({
                  label: account.value || account,
                  value: account.value || account,
                }))}
                onChange={this.setSwimlaneAccounts}
                accounts={this.props.accounts}
                styles={selectStyles}
                disabled={this.props.creatingSwimlane}
                hasAccountNumber
              />
              <div
                style={{
                  color: `rgb(86, 86, 86)`,
                  fontSize: '0.9rem',
                  paddingTop: '1rem',
                }}
              >
                Tag, Resource Name, and Resource Id filters are then applied as
                an OR&apos;d manner within the selected account(s)
              </div>
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <FormLabel>Matches Resource Tags</FormLabel>
                  <TextLink
                    onClick={() =>
                      this.setState(oldState => ({
                        advanced: !oldState.advanced,
                      }))
                    }
                    color="primary"
                    style={{ fontSize: '0.9rem', marginTop: '1em' }}
                    disabled={this.props.creatingSwimlane}
                  >
                    {!this.state.advanced ? 'Tag Builder' : 'Basic Tag Input'}
                  </TextLink>
                </div>
                <SwimlaneTagManager
                  isAdvanced={this.state.advanced}
                  swimlaneTags={this.state.swimlaneTags}
                  onChange={this.setSwimlaneTags}
                  fetchTags={this.props.fetchTags}
                  options={this.getTagOptions()}
                  selectStyles={selectStyles}
                  isLoadingTagValues={this.props.isLoadingTagValues}
                  tagValues={this.props.tagValues}
                  getTagValues={this.props.getTagValues}
                  disabled={this.props.creatingSwimlane}
                />
              </div>

              <FormLabel>
                Matches Resource Names{' '}
                <small>(Use * to define a wildcard match)</small>
              </FormLabel>
              <MultiValueInput
                value={this.state.swimlaneNames}
                onChange={this.setSwimlaneNames}
                disabled={this.props.creatingSwimlane}
              />

              <FormLabel>
                Matches Resource Ids{' '}
                <small>(Use * to define a wildcard match)</small>
              </FormLabel>
              <MultiValueInput
                value={this.state.swimlaneResourceIds}
                onChange={this.setSwimlaneResourceIds}
                disabled={this.props.creatingSwimlane}
              />

              <SectionHeader small style={{ marginTop: '2em' }}>
                3. Swimlane Bots
              </SectionHeader>

              <p>
                Prevention bots apply cloud-native policies to all accounts
                included in this swimlane. These policies can block
                functionality in your environment, so carefully review which
                preventions you enable.
              </p>
              <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr' }}>
                <PlainCheckbox
                  disabled={this.props.creatingSwimlane}
                  checked={this.state.swimlanePreventionEnabled}
                  onChange={this.setPreventionEnabled}
                />
                <FormLabel style={{ marginTop: 0 }}>
                  Enable Prevention Bots
                </FormLabel>
              </div>

              <FormLabel>Add Prevention Bot(s)</FormLabel>
              <AsyncTypeahead
                onChange={this.handleBotsChange}
                selectedValue={this.state.swimlaneBots.map(bot => ({
                  label: bot.label || bot.title,
                  value: bot.value || bot.srn,
                }))}
                options={this.getBotOptions()}
                loadOptions={this.props.getBotValues}
                styles={selectStyles}
                isDisabled={this.props.creatingSwimlane}
                getOptionLabel={option => this.getBotBadge(option.value)}
              />
            </div>
          ) : (
            <div>
              <Breadcrumb>
                <BreadcrumbItem>
                  <TextLink color="secondary" onClick={this.props.cancel}>
                    Swimlanes
                  </TextLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <TextLink color="secondary" onClick={this.closePreview}>
                    Add Swimlane
                  </TextLink>
                </BreadcrumbItem>
                <BreadcrumbItem>Preview</BreadcrumbItem>
              </Breadcrumb>
              <SectionHeader small style={{ marginTop: '1em' }}>
                {this.getHeaderLabel()}
              </SectionHeader>
              {this.props.previewResultsLoading ? (
                <div style={{ height: '500px' }}>
                  <CenterContent style={{ height: '100%' }}>
                    <SquareLoadingAnimation />
                  </CenterContent>
                </div>
              ) : (
                <SwimlanePreview resources={this.props.previewResults} />
              )}
            </div>
          )}
        </ModalBody>
        <ModalFooter>{this.renderButtons(nameIsValid)}</ModalFooter>
      </Fragment>
    )
  }
}

CreateEditForm.propTypes = {
  theme: themeShape,
  accounts: ImmutablePropTypes.iterable.isRequired,
  cancel: PropTypes.func.isRequired,
  createSwimlane: PropTypes.func.isRequired,
  creatingSwimlane: PropTypes.bool,
  editingSwimlane: ImmutablePropTypes.map,
  fetchTags: PropTypes.func.isRequired,
  swimlanes: ImmutablePropTypes.map.isRequired,
  tags: ImmutablePropTypes.list.isRequired,
  toggle: PropTypes.func,
  previewResultsLoading: PropTypes.bool,
  previewResults: ImmutablePropTypes.list,
  getPreviewResults: PropTypes.func,
  isLoadingTagValues: PropTypes.bool,
  tagValues: ImmutablePropTypes.iterable,
  getTagValues: PropTypes.func,
  getBotValues: PropTypes.func,
  setManagementError: PropTypes.func,
  botValues: ImmutablePropTypes.list,
  error: PropTypes.string,
}

const mapStateToProps = createStructuredSelector({
  accounts: selectAccounts,
  creatingSwimlane: selectCreatingSwimlane,
  swimlanes: selectSwimlanes,
  tags: selectTags,
  previewResults: selectPreviewResults,
  previewResultsLoading: selectPreviewResultsLoading,
  isLoadingTagValues: selectIsLoadingTagValues,
  tagValues: selectTagValues,
  botValues: selectBotValues,
  error: selectError,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createSwimlane,
      fetchTags,
      getPreviewResults,
      getTagValues,
      getBotValues,
      setManagementError,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(themeable(CreateEditForm))

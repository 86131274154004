/*
 *
 * SwimlaneManager actions
 *
 */

import {
  GET_PREVIEW_RESULTS,
  SET_PREVIEW_RESULTS,
  CREATE_SWIMLANE,
  CREATE_SWIMLANE_SUCCESS,
  GET_TAG_VALUES,
  SET_TAG_VALUES,
  GET_BOT_VALUES,
  SET_BOT_VALUES,
  SET_MANAGEMENT_ERROR,
} from './constants'

import { createAction } from 'redux-actions'

export const getPreviewResults = createAction(GET_PREVIEW_RESULTS)
export const setPreviewResults = createAction(SET_PREVIEW_RESULTS)
export const createSwimlane = createAction(CREATE_SWIMLANE)
export const createSwimlaneSuccess = createAction(CREATE_SWIMLANE_SUCCESS)
export const getTagValues = createAction(GET_TAG_VALUES)
export const setTagValues = createAction(SET_TAG_VALUES)
export const getBotValues = createAction(GET_BOT_VALUES)
export const setBotValues = createAction(SET_BOT_VALUES)
export const setManagementError = createAction(SET_MANAGEMENT_ERROR)

import React, { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import BorderedCard from 'components/BorderedCard'
import CloudAccount from 'components/CloudAccount'
import Badge from 'components/Badge'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { List } from 'immutable'

class SwimlaneFilterDisplay extends Component {
  constructor(props) {
    super(props)

    this.styles = {
      containers: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0.75rem',
      },
      containerTitles: {
        fontSize: '0.95rem',
        textAlign: 'left',
        fontWeight: '400',
        marginBottom: '0.5rem',
        alignSelf: 'flex-start',
      },
      empty: {
        fontStyle: 'italic',
        color: props.theme.neutralMedium,
      },
      cellGuy: props.inTable
        ? {
            height: '75px',
            overflow: 'auto',
          }
        : {},
    }
  }

  render() {
    const { swimlane } = this.props
    const tags = swimlane.get('tags') || List()
    const names = swimlane.get('names') || List()
    const resources = swimlane.get('resourceIds') || List()
    const accounts = swimlane.get('accounts') || List()
    return (
      <BorderedCard style={{ marginTop: '1em' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
          }}
        >
          <div style={this.styles.containers}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={this.styles.containerTitles}>
                Restricted to Accounts
              </div>
            </div>
            <div style={{ maxheight: '130px', overflow: 'auto' }}>
              {accounts.isEmpty() ? (
                <span style={this.styles.empty}>( All Accounts )</span>
              ) : (
                <div
                  style={
                    this.props.inTable
                      ? {
                          display: 'grid',
                          gridTemplateColumns: 'auto auto auto',
                          ...this.styles.cellGuy,
                        }
                      : {}
                  }
                >
                  {accounts.map(accountId => (
                    <CloudAccount
                      key={accountId}
                      accountId={accountId}
                      style={{ margin: '0 1em', display: 'inline-block' }}
                      static={false}
                      hasLink={true}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
          <div style={this.styles.containers}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={this.styles.containerTitles}>Tag Filters</div>
            </div>

            {tags.isEmpty() ? (
              <span style={this.styles.empty}>( Any Tag )</span>
            ) : (
              <div style={this.styles.cellGuy}>
                {tags.map((tag, index) => {
                  return (
                    <div key={index}>
                      <Badge
                        style={{
                          margin: '0.5em 0.5em 0.5em 0',
                          backgroundColor: this.props.theme.neutralDark,
                        }}
                      >
                        {tag}
                      </Badge>
                    </div>
                  )
                })}
              </div>
            )}
          </div>

          <div style={this.styles.containers}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={this.styles.containerTitles}>Name Filters</div>
            </div>
            {names.isEmpty() ? (
              <span style={this.styles.empty}>( Any Name )</span>
            ) : (
              <div style={this.styles.cellGuy}>
                {names.map((name, index) => (
                  <div key={index}>{name}</div>
                ))}
              </div>
            )}
          </div>

          <div style={this.styles.containers}>
            <div style={this.styles.containerTitles}>Resource ID Filters</div>
            {resources.isEmpty() ? (
              <span style={this.styles.empty}>( Any Resource ID )</span>
            ) : (
              <div style={this.styles.cellGuy}>
                {resources.map((resourceFilter, index) => (
                  <div key={index}>{resourceFilter}</div>
                ))}
              </div>
            )}
          </div>
        </div>
      </BorderedCard>
    )
  }
}

SwimlaneFilterDisplay.propTypes = {
  theme: themeShape,
  swimlane: ImmutablePropTypes.iterable,
  inTable: PropTypes.bool,
}

export default themeable(SwimlaneFilterDisplay)

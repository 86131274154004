import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import TextLink from 'components/TextLink'
import { exists } from 'utils/sonraiUtils'
import Button from 'components/Button'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Createable from 'react-select/creatable'

import messages from './messages'

class SwimlaneCreateTag extends Component {
  constructor(props) {
    super(props)

    this.state = {
      key: '',
      value: '',
      error: null,
    }

    this.styles = {
      container: {
        display: 'grid',
        gridTemplateColumns: '300px 300px auto auto 1fr',
        alignItems: 'center',
        gridColumnGap: '1em',
      },
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.key !== this.state.key) {
      if (this.state.key === '') {
        this.props.getTagValues(null)
      } else {
        this.props.getTagValues(this.state.key)
      }
    }
  }

  handleCloseEdit = () => {
    this.setState({
      key: '',
      value: '',
    })
    this.props.closeEdit()
  }

  handleAddTag = () => {
    this.props.closeEdit()

    this.props.onChange({
      key: this.state.key,
      value: this.state.value,
      toRemove: false,
    })
  }

  handleKeyChange = params =>
    params
      ? this.setState({ key: params.label }, this.validate)
      : this.setState({ key: '' }, this.validate)

  handleValueChange = params =>
    params
      ? this.setState({ value: params.label }, this.validate)
      : this.setState({ value: '' }, this.validate)

  validate = () => {
    let isValid = true
    if ((this.state.key || '').includes('*')) {
      isValid = false
      this.setState({ error: messages.keyNoStar })
    }
    if (isValid && (this.state.value || '').includes('*')) {
      isValid = false
      this.setState({ error: messages.valueNoStar })
    }
    if (isValid) {
      this.setState({ error: null })
    }
  }

  render() {
    const { tagValues } = this.props
    const valueOptions =
      exists(tagValues) && !tagValues.isEmpty()
        ? tagValues.toJS().map(tag => ({
            label: tag,
            value: tag,
          }))
        : []

    let isAddDisabled = false
    if (this.state.key.trim() === '' && this.state.value.trim() === '') {
      isAddDisabled = true
    }
    if (this.state.error != null) {
      isAddDisabled = true
    }

    return (
      <Fragment>
        <div style={this.styles.container}>
          <div style={this.styles.inputContainer}>
            <Createable
              placeholder="Select Key..."
              isClearable
              onChange={this.handleKeyChange}
              options={this.props.options.map(option => ({
                label: option.label,
                value: option,
              }))}
              isDisabled={this.props.disabled}
            />
          </div>
          <div style={this.styles.inputContainer}>
            <Createable
              placeholder="Select Value..."
              isClearable
              isLoading={this.props.isLoading}
              onChange={this.handleValueChange}
              options={valueOptions}
              isDisabled={this.props.disabled}
            />
          </div>
          <Button
            color="primary"
            onClick={this.handleAddTag}
            disabled={isAddDisabled || this.props.disabled}
          >
            Add
          </Button>
          {this.props.showCloseEdit && (
            <TextLink
              disabled={this.props.disabled}
              color="primary"
              onClick={this.handleCloseEdit}
            >
              Cancel
            </TextLink>
          )}
        </div>
        {this.state.error && (
          <div
            style={{
              color: 'red',
              padding: 1,
            }}
          >
            <DynamicFormattedMessage {...this.state.error} />
          </div>
        )}
      </Fragment>
    )
  }
}

SwimlaneCreateTag.propTypes = {
  onChange: PropTypes.func,
  closeEdit: PropTypes.func,
  getTagValues: PropTypes.func,
  tagValues: ImmutablePropTypes.iterable,
  isLoading: PropTypes.bool,
  options: PropTypes.array,
  showCloseEdit: PropTypes.bool,
  disabled: PropTypes.bool,
}
export default SwimlaneCreateTag

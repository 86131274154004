import { all, put, takeLatest, call } from 'redux-saga/effects'
import gql from 'graphql-tag'
import _ from 'lodash'
import { getClient } from 'apolloClient'
import { addSwimlane } from 'containers/SonraiData/actions'
import { formatCreateSwimlaneTags } from 'utils/graphDataUtils'

import {
  createSwimlaneSuccess,
  setPreviewResults,
  setTagValues,
  setBotValues,
  setManagementError,
} from './actions'
import { setShouldUpdateNodeSwimlanes } from 'containers/NodeSolutionCenter/actions'

import {
  CREATE_SWIMLANE,
  GET_PREVIEW_RESULTS,
  GET_TAG_VALUES,
  GET_BOT_VALUES,
} from './constants'

import { GET_ALL_BOTS } from 'containers/BotManagement/static-queries'

function* createSwimlane(action) {
  let { swimlane, bots } = action.payload
  swimlane.tags = formatCreateSwimlaneTags(swimlane.tags)
  let botValues = []
  try {
    const client = getClient()
    const results = yield client.mutate({
      mutation: gql`
        mutation createSwimlane($swimlane: SwimlaneCreator!) {
          CreateSwimlane(value: $swimlane) {
            description
            label
            title
            srn
            defaultImportance
            createdBy
            sid
            preventionEnabled
            lastModified
            createdDate
            name
            accounts
            names
            resourceIds
            tags
            resourceId
          }
        }
      `,
      variables: { swimlane },
    })

    if (results.errors) {
      yield put(
        setManagementError(
          _.get(results, ['errors', 0, 'message'], 'Error creating swimlane')
        )
      )
    } else {
      const createdSwimlane = results.data.CreateSwimlane

      if (bots && !_.isEmpty(bots)) {
        for (let i = 0; i < bots.length; i++) {
          const bot = yield call(
            createBotAssignement,
            bots[i].value,
            createdSwimlane.srn
          )
          botValues.push(bot)
        }
      }

      createdSwimlane.bots = botValues
      yield put(createSwimlaneSuccess(createdSwimlane))
      yield put(addSwimlane(createdSwimlane))
      yield put(setShouldUpdateNodeSwimlanes(true))
    }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error creating swimlanes', e)
  }
}

function* createBotAssignement(botSrn, contentSrn) {
  try {
    const client = getClient()
    const response = yield client.mutate({
      mutation: gql`
      mutation attachBotToSwimlane { 
          CreateBotAssignments(input: { 
              botSrn: "${botSrn}",
              contentSrn: "${contentSrn}"
          }) {
            items { 
              bot {
                srn
                title
                url
              } 
            }
          }
      } 
      `,
    })
    if (response.data.CreateBotAssignments) {
      if (!_.isEmpty(response.data.CreateBotAssignments.items)) {
        const bot = _.get(
          _.first(response.data.CreateBotAssignments.items),
          'bot'
        )
        return bot
      }
    } else {
      return {}
    }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error creating bot assignment', e)
  }
}

function* previewSwimlane(action) {
  try {
    const params = { ...action.payload }
    let coupleOfOrsUpInHere = ''
    if (params.accounts && params.accounts.length > 0) {
      params.accounts.forEach(account => {
        if (params.resourceIds) {
          params.resourceIds.forEach(resourceId => {
            const letters = resourceId.split('')
            const regex = letters
              .map(letter => {
                if (letter === '*') {
                  return '.*'
                } else {
                  return `[${letter.toUpperCase()}${letter.toLowerCase()}]`
                }
              })
              .join('')
            coupleOfOrsUpInHere += `
              { account: { op: IN_LIST values: ["${account}"] } resourceId: { op: REGEX value: "${regex}" caseSensitive: false } }`
          })
        }
        if (params.resourceNames) {
          params.resourceNames.forEach(resourceName => {
            const letters = resourceName.split('')
            const regex = letters
              .map(letter => {
                if (letter === '*') {
                  return '.*'
                } else {
                  return `[${letter.toUpperCase()}${letter.toLowerCase()}]`
                }
              })
              .join('')
            coupleOfOrsUpInHere += `
              { account: { op: IN_LIST values: ["${account}"] } name: { op: REGEX value: "${regex}" caseSensitive: false } }`
          })
        }
        if (params.tags) {
          params.tags.forEach(tag => {
            const letters = tag.split('')
            const regex = letters
              .map(letter => {
                if (letter === '*') {
                  return '.*'
                } else {
                  return `[${letter.toUpperCase()}${letter.toLowerCase()}]`
                }
              })
              .join('')
            coupleOfOrsUpInHere += `
              { account: { op: IN_LIST values: ["${account}"] } tagSet: { op: REGEX value: "${regex}" caseSensitive: false } }`
          })
        }
        if (!coupleOfOrsUpInHere) {
          coupleOfOrsUpInHere += `{ account: { op: IN_LIST values: ["${account}"] } }`
        }
      })
    } else {
      if (params.resourceIds) {
        params.resourceIds.forEach(resourceId => {
          const letters = resourceId.split('')
          const regex = letters.map(letter => {
            if (letter === '*') {
              return '.*'
            } else {
              return `[${letter.toUpperCase()}${letter.toLowerCase()}]`
            }
          })
          coupleOfOrsUpInHere += `
            { resourceId: { op: REGEX value: "${regex}" caseSensitive: false } }`
        })
      }
      if (params.resourceNames) {
        params.resourceNames.forEach(resourceName => {
          const letters = resourceName.split('')
          const regex = letters
            .map(letter => {
              if (letter === '*') {
                return '.*'
              } else {
                return `[${letter.toUpperCase()}${letter.toLowerCase()}]`
              }
            })
            .join('')
          coupleOfOrsUpInHere += `
            { name: { op: REGEX value: "${regex}" caseSensitive: false } }`
        })
      }
      if (params.tags) {
        params.tags.forEach(tag => {
          const letters = tag.split('')
          const regex = letters
            .map(letter => {
              if (letter === '*') {
                return '.*'
              } else {
                return `[${letter.toUpperCase()}${letter.toLowerCase()}]`
              }
            })
            .join('')
          coupleOfOrsUpInHere += `
            { tagSet: { op: REGEX value: "${regex}" caseSensitive: false } }`
        })
      }
    }
    // because it's tall and an abomination against the lord
    let queryOfBabel = `
      query preview {
        Resources(where: {
          or: [
            ${coupleOfOrsUpInHere}
          ],
          and: [
            { highestAlertSeverity: { op: GT, value: 0 } }
          ]
        }) {
          items(limit: 75) {
            account
            friendlyName
            name
            resourceId
            srn
            tagSet
          }
        }
      }
    `

    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${queryOfBabel}
      `,
    })

    if (results.errors) {
      yield put(
        setManagementError(
          _.get(results, ['errors', 0, 'message'], 'Error creating swimlane')
        )
      )
    } else {
      yield put(setPreviewResults(results.data.Resources.items))
    }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error creating swimlanes', e)
  }
}

function* getTagValues(action) {
  const { payload } = action
  if (!payload) {
    yield put(setTagValues(null))
  } else {
    try {
      const client = getClient()
      const results = yield client.query({
        query: gql`
          query getTagValuesByKey($key: String!) {
            Tags(where: { key: { value: $key } }) {
              group {
                key {
                  key
                  value
                }
              }
            }
          }
        `,
        variables: { key: action.payload },
      })

      const items = results.data.Tags.group
      const values = items.map(item => item.key.value)
      yield put(setTagValues(values))
    } catch (e) {
      //eslint-disable-next-line no-console
      console.error('error getting tag values ', e)
    }
  }
}

// function* getControlFrameworksForSwimlane(action) {
//   const CONTROL_FRAMEWORK_SWIMLANE = `
//     query getControlFrameworks($srns: [String]) {
//       ControlFrameworks(where: {
//         swimlaneSRNs: { op: IN_LIST, values: $srns }
//       }) {
//         items {
//           description
//           loadId
//           policyCount: contains {
//             count
//           }
//           swimlaneSRNs
//           shortDescription
//           enabled
//           title
//           preventionEnabled
//           remediationEnabled
//           createdBy
//           sid
//           lastModified
//           createdDate
//           name
//           srn
//           resourceId
//         }
//       }
//     }
//   `
//   try {
//     const client = getClient()
//     const results = yield client.query({
//       query: gql`
//         ${CONTROL_FRAMEWORK_SWIMLANE}
//       `,
//       variables: { srns: [action.payload.swimlane] },
//     })
//     const frameworks = _.get(
//       results,
//       ['data', 'ControlFrameworks', 'items'],
//       []
//     )

//     yield put(
//       setControlFrameworksForSwimlane({
//         swimlane: action.payload.swimlane,
//         data: frameworks,
//       })
//     )
//   } catch (e) {
//     //eslint-disable-next-line no-console
//     console.error('error getting tag values ', e)
//   }
// }

function* getBots() {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${GET_ALL_BOTS}
      `,
    })
    yield put(setBotValues(results.data.Bots.items))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error getting bot values ', e)
  }
}

function* swimlaneManagerSaga() {
  yield all([
    takeLatest(CREATE_SWIMLANE, createSwimlane),
    takeLatest(GET_PREVIEW_RESULTS, previewSwimlane),
    takeLatest(GET_TAG_VALUES, getTagValues),
    takeLatest(GET_BOT_VALUES, getBots),
  ])
}

export default swimlaneManagerSaga

import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import { ModalBody, ModalFooter, Modal } from 'reactstrap'
const styles = {
  base: {
    padding: '1rem 1.5rem',
  },
  header: {
    fontSize: '30px',
    color: '#373835',
    display: 'block',
    textAlign: 'center',
    fontWeight: '300',
    marginBottom: '0.25rem',
  },
  description: {
    fontSize: '17px',
    fontWeight: '300',
    margin: '10px 0px',
  },
  sampleFilter: {
    fontWeight: '300',
    margin: '0.5rem',
  },
  subtitle: {
    fontSize: '17px',
    fontWeight: '400',
  },
  list: {
    listStyleType: 'disc',
    padding: '0rem 2rem',
  },
}
export class SwimlaneHelpModal extends React.Component {
  render() {
    return (
      <Modal isOpen={this.props.isOpen} style={{ minWidth: '75vw' }}>
        <ModalBody>
          <div style={styles.base}>
            <div style={styles.header}>Swimlane Management</div>
            <div style={styles.description}>
              Swimlanes can be defined using a combination of accounts, resource
              names & filters, or tag key & value pairs. Select &quot;+Add
              Swimlane&quot; to begin.
            </div>
            <div style={styles.subtitle}>Sample Filters:</div>
            <ul style={styles.list}>
              <li style={styles.sampleFilter}>
                Accounts 1234 and abcwxyz - resources from account 1234 and
                abcwxyz.
              </li>
              <li style={styles.sampleFilter}>
                Account = 1, 2, 3, and Tag environment:production - include any
                resources from accounts 1, 2 & 3 and that are tagged with the
                key:value &quot;environment:production&quot;.
              </li>
              <li style={styles.sampleFilter}>
                No Account filter, and Tag BillingCode:MRKT04 - include any
                resources in any account, that are tagged with the key:value
                &quot;BillingCode:MRKT04&quot;.
              </li>
              <li style={styles.sampleFilter}>
                Account = 8, no Tag, Resource name = creditservice* - includes
                resources from account 8, no tag filter, and resources that
                include the string &quot;creditservice&quot; in the name.
              </li>
            </ul>
            <div style={styles.subtitle}>Filter Notes:</div>
            <ul style={styles.list}>
              <li style={styles.sampleFilter}>
                The Account filter provides a list of detected accounts for you
                to choose from. If you have just added a new account to be
                monitored via &quot;Configure Collectors&quot;, it may not
                immediately show up in the filter list as an available option.
              </li>
              <li style={styles.sampleFilter}>
                Resource Name and Tags filters use an exact pattern match. If
                you want to use &quot;prod&quot; to match both the term
                &quot;prod&quot; and &quot;production&quot;, add an asterisk *
                to the end of &quot;prod&quot; to allow for a wildcard pattern
                match.
              </li>
              <li style={styles.sampleFilter}>
                The Account filter provides a list of detected accounts for you
                to choose from. If you have just added a new account to be
                monitored via &quot;Configure Collectors&quot;, it may not
                immediately show up in the filter list as an available option.
              </li>
              <li style={styles.sampleFilter}>
                Resource Name includes any resources defined within your cloud -
                users, roles, networking, compute, data, etc.
              </li>
              <li style={styles.sampleFilter}>
                Account = 8, no Tag, Resource name = creditservice* - includes
                resources from account 8, no tag filter, and resources that
                include the string &quot;creditservice&quot; in the name.
              </li>
            </ul>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

SwimlaneHelpModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
}

export default SwimlaneHelpModal

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import AsyncTypeahead from 'components/AsyncTypeahead'
import TextLink from 'components/TextLink'
import _ from 'lodash'
import Icon from 'components/Icon'
import SwimlaneTag from './SwimlaneTag'
import SwimlaneCreateTag from './SwimlaneCreateTag'

class SwimlaneTagManager extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEditing: true,
    }

    this.styles = {
      labelContainer: {
        display: 'grid',
        gridTemplateColumns: '300px 300px 1fr',
        alignItems: 'center',
        gridColumnGap: '1em',
        fontSize: '0.8em',
        fontWeight: 500,
        color: props.theme.neutralMedium,
      },
    }
  }

  render() {
    if (!this.props.isAdvanced) {
      return (
        <AsyncTypeahead
          onChange={this.props.onChange}
          selectedValue={this.props.swimlaneTags.map(tag => ({
            label: tag,
            value: tag,
          }))}
          options={this.props.options}
          loadOptions={this.props.fetchTags}
          allowCreateWhileLoading={true}
          styles={this.props.selectStyles}
          isDisabled={this.props.disabled}
        />
      )
    } else {
      return (
        <div>
          <div style={this.styles.labelContainer}>
            <div>Tag Key</div>
            <div>Tag Value</div>
          </div>
          {!_.isEmpty(this.props.swimlaneTags) && (
            <div>
              {this.props.swimlaneTags.map((tag, i) => {
                const tagKey = _.first(tag.split(':'))
                const tagValue =
                  tag.split(':').length > 1 ? _.last(tag.split(':')) : ''
                return (
                  <SwimlaneTag
                    key={i}
                    tagKey={tagKey}
                    tagValue={tagValue}
                    onChange={this.props.onChange}
                    disabled={this.props.disabled}
                  />
                )
              })}
            </div>
          )}

          {this.state.isEditing || _.isEmpty(this.props.swimlaneTags) ? (
            <SwimlaneCreateTag
              closeEdit={() => this.setState({ isEditing: false })}
              options={this.props.options}
              isLoading={this.props.isLoadingTagValues}
              getTagValues={this.props.getTagValues}
              tagValues={this.props.tagValues}
              onChange={this.props.onChange}
              showCloseEdit={!_.isEmpty(this.props.swimlaneTags)}
              disabled={this.props.disabled}
            />
          ) : (
            <div style={{ margin: '0.25rem' }}>
              <TextLink
                onClick={() =>
                  this.setState({
                    isEditing: true,
                  })
                }
                color="primary"
                disabled={this.props.disabled}
              >
                <Icon fa name="plus" />
                &nbsp; Add Tag
              </TextLink>
            </div>
          )}
        </div>
      )
    }
  }
}

SwimlaneTagManager.propTypes = {
  isAdvanced: PropTypes.bool,
  fetchTags: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array,
  selectStyles: PropTypes.object,
  swimlaneTags: PropTypes.array,
  theme: themeShape,
  isLoadingTagValues: PropTypes.bool,
  tagValues: ImmutablePropTypes.iterable,
  getTagValues: PropTypes.func,
  disabled: PropTypes.bool,
}

export default themeable(SwimlaneTagManager)

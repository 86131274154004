/*
 *
 * SwimlaneManager reducer
 *
 */

import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'

import {
  GET_PREVIEW_RESULTS,
  SET_PREVIEW_RESULTS,
  CREATE_SWIMLANE,
  CREATE_SWIMLANE_SUCCESS,
  GET_TAG_VALUES,
  SET_TAG_VALUES,
  SET_BOT_VALUES,
  SET_MANAGEMENT_ERROR,
} from './constants'

const initialState = fromJS({
  previewResults: [],
  loadingPreviewResults: false,
  creatingSwimlane: false,
  loadingTagValues: false,
  tagValues: null,
  botValues: [],
  error: null,
})

const swimlaneManagerReducer = handleActions(
  {
    [CREATE_SWIMLANE]: state => state.set('creatingSwimlane', true),
    [CREATE_SWIMLANE_SUCCESS]: state =>
      state.set('creatingSwimlane', false).set('error', null),
    [GET_PREVIEW_RESULTS]: state => state.set('loadingPreviewResults', true),
    [SET_PREVIEW_RESULTS]: (state, { payload }) =>
      state
        .set('previewResults', fromJS(payload))
        .set('loadingPreviewResults', false),
    [SET_BOT_VALUES]: (state, { payload }) =>
      state.set('botValues', fromJS(payload)),
    [GET_TAG_VALUES]: state => state.set('loadingTagValues', true),
    [SET_TAG_VALUES]: (state, { payload }) =>
      state.set('loadingTagValues', false).set('tagValues', fromJS(payload)),
    [SET_MANAGEMENT_ERROR]: (state, { payload }) =>
      state
        .set('error', payload)
        .set('loadingPreviewResults', false)
        .set('creatingSwimlane', false),
  },
  initialState
)

export default swimlaneManagerReducer

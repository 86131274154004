/*
 *
 * SwimlaneManager constants
 *
 */

export const GET_PREVIEW_RESULTS = 'app/SwimlaneManager/GET_PREVIEW_RESULTS'
export const SET_PREVIEW_RESULTS = 'app/SwimlaneManager/SET_PREVIEW_RESULTS'
export const CREATE_SWIMLANE = 'app/SwimlaneManager/CREATE_SWIMLANE'
export const CREATE_SWIMLANE_SUCCESS =
  'app/SwimlaneManager/CREATE_SWIMLANE_SUCCESS'

export const GET_TAG_VALUES = 'app/SwimlaneManager/GET_TAG_VALUES'
export const SET_TAG_VALUES = 'app/SwimlaneManager/SET_TAG_VALUES'

export const GET_BOT_VALUES = 'app/SwimlaneManager/GET_BOT_VALUES'
export const SET_BOT_VALUES = 'app/SwimlaneManager/SET_BOT_VALUES'

export const SET_MANAGEMENT_ERROR = 'app/SwimlaneManager/SET_MANAGEMENT_ERROR'

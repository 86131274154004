/**
 *
 * SwimlaneManager
 *
 */

import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { Modal } from 'reactstrap'
import { push } from 'connected-react-router'
import qs from 'query-string'
import { List, Map } from 'immutable'
import TextLink from 'components/TextLink'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import Icon from 'components/Icon'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { stripTags } from 'utils/sonraiUtils'
import DataTable from 'components/DataTable'
import Button from 'components/Button'
import BorderedCard from 'components/BorderedCard'
import IHelp from 'containers/IHelp'
import CreatedByBadge from 'components/CreatedByBadge'
import reducer from './reducer'
import CreateEditForm from './CreateEditForm'
import SwimlaneHelpModal from './SwimlaneHelpModal'
import messages from './messages'
import { selectSwimlanesLoading } from './selectors'
import {
  selectSwimlanes,
  selectSonraiUsers,
} from 'containers/SonraiData/selectors'
import sagas from './sagas'
import SwimlaneFilterDisplay from 'containers/SwimlaneDetails/SwimlaneFilterDisplay'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

const FiltersRenderer = ({ data, swimlanes }) => {
  if (!data || !swimlanes) {
    return
  }
  const { srn } = data
  const swimlane =
    swimlanes.find(swimalne => swimalne.get('srn') === srn) || Map()
  if (swimlane && !swimlane.isEmpty()) {
    return <SwimlaneFilterDisplay inTable={true} swimlane={swimlane} />
  }
}

export class SwimlaneManager extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      createOpen: false,
      helpModalOpen: false,
    }
    this.styles = {
      container: {
        padding: '1em',
        width: '100%',
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
      },
      title: {
        fontSize: '1.5em',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
      },
      header: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
      },
    }
  }

  toggleHelpModal = () => {
    this.setState(prevState => ({ helpModalOpen: !prevState.helpModalOpen }))
  }

  closeHelpModal = () => {
    this.setState({
      helpModalOpen: false,
    })
  }

  toggleCreateModal = () => {
    this.setState(prevState => ({ createOpen: !prevState.createOpen }))
  }

  closeCreateModal = () => {
    this.setState({
      createOpen: false,
    })
  }

  navigateToDetails = row => {
    this.props.push({
      pathname: '/App/SwimlaneDetails',
      search: qs.stringify({
        srn: row.srn,
      }),
    })
  }

  onLoad = ({ api }) => {
    this.gridApi = api

    //this makes everything go weird trying to start off with the first one open
    // if (this.gridApi) {
    //   if (
    //     this.gridApi.rowModel &&
    //     !_.isEmpty(this.gridApi.rowModel.rowsToDisplay)
    //   ) {
    //     const node = _.first(this.gridApi.rowModel.rowsToDisplay)
    //     node.setExpanded(true)
    //   }
    // }
  }

  getSwimmiBoiz = () => {
    return (this.props.swimlanes || Map())
      .map((swimmi, name) => {
        return {
          name,
          description: swimmi.get('description', ''),
          importance: swimmi.get('defaultImportance', ''),
          filters: '',
          preventionBots: (swimmi.get('bots') || List()).size,
          botsEnabled: swimmi.get('preventionEnabled') ? true : false,
          createdBy: swimmi.get('createdBy', ''),
          createdByUser: this.props.sonraiUsers.get(
            swimmi.get('createdBy'),
            Map()
          ),
          srn: swimmi.get('srn', ''),
        }
      })
      .toList()
      .toJS()
  }

  getPreviewText = (accounts, tags, names, resources) => {
    let accountNum =
      accounts === 0
        ? 'All Acccounts, '
        : accounts === 1
        ? '1 Account, '
        : `${accounts} Accounts, `
    let tagsNum =
      tags === 0 ? 'Any Tag, ' : tags === 1 ? '1 Tag, ' : `${tags} Tags, `
    let namesNum =
      names === 0 ? 'Any Name, ' : names === 1 ? '1 Name, ' : `${names} Names, `
    let resourcesNum =
      resources === 0
        ? 'Any Resource ID '
        : resources === 1
        ? '1 Resource ID '
        : `${resources} Resource IDs `

    return `${accountNum} ${tagsNum} ${namesNum} ${resourcesNum}`
  }

  renderHelpIcon = () => {
    return (
      <div
        style={{
          margin: '0rem -1rem 0rem 0.5rem',
        }}
      >
        <IHelp
          help
          id="searchDescription"
          customContent={() => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '0.75rem',
                minHeight: '100px',
                minWidth: '250px',
              }}
            >
              {messages.swomboHelp.defaultMessage}
              <TextLink color="primary" onClick={this.toggleHelpModal}>
                Read More
              </TextLink>
            </div>
          )}
          iconSize="22px"
          position="bottom"
        />
      </div>
    )
  }

  render() {
    if (this.props.swimlanesLoading) {
      return <SquareLoadingAnimation />
    }
    const swimmiBoiz = this.getSwimmiBoiz()

    return (
      <div style={this.styles.container}>
        <div style={this.styles.header}>
          <div style={this.styles.title}>
            <div>All Swimlanes{` `}</div>
            {this.renderHelpIcon()}
          </div>
          <WithPermission permissionName="edit.swimlanes">
            <Button
              style={{ marginBottom: '0.5em' }}
              color="primary"
              onClick={this.toggleCreateModal}
            >
              <Icon fa name="plus" /> Add Swimlane
            </Button>
          </WithPermission>
        </div>
        <BorderedCard>
          <DataTable
            onLoad={this.onLoad}
            autosize={false}
            onDoubleClickRow={this.navigateToDetails}
            data={swimmiBoiz}
            customGridProps={{
              groupUseEntireRow: true,
              overlayLoadingTemplate:
                '<span class="ag-overlay-loading-center">Working...</span>',
              masterDetail: true,
              detailRowHeight: 145,
              detailCellRenderer: 'FiltersRenderer',
              frameworkComponents: {
                FiltersRenderer: ({ data }) => (
                  <FiltersRenderer
                    data={data}
                    swimlanes={this.props.swimlanes}
                  />
                ),
              },
              detailCellRendererParams: {
                detailGridOptions: {
                  columnDefs: [{ field: 'filters' }],
                },
              },
            }}
            customColumnConfig={{
              srn: {
                hide: true,
              },
              createdByUser: {
                hide: true,
              },
              createdBy: {
                minWidth: 200,
                width: 200,
                valueGetter: params => {
                  if (!params.data) {
                    return ''
                  }

                  return params.data.createdByUser.name
                    ? stripTags(params.data.createdByUser.name)
                    : 'Sonrai'
                },
                cellRendererFramework: ({ data }) => {
                  if (!data) {
                    return null
                  }
                  return <CreatedByBadge table createdBy={data.createdBy} />
                },
              },
              filters: {
                sortable: false,
                minWidth: 550,
                width: 550,
                cellRendererFramework: params => {
                  const { data } = params
                  if (!data) {
                    return null
                  }
                  const { srn } = data
                  const swimlane =
                    this.props.swimlanes.find(
                      swimalne => swimalne.get('srn') === srn
                    ) || Map()
                  const tags = swimlane.get('tags') || List()
                  const names = swimlane.get('names') || List()
                  const resources = swimlane.get('resourceIds') || List()
                  const accounts = swimlane.get('accounts') || List()
                  return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          color: this.props.theme.neutralMedium,
                          fontSize: '0.8rem',
                          margin: '0rem 0.5rem 0rem 0rem',
                          fontStyle: 'italic',
                        }}
                      >
                        ({' '}
                        {this.getPreviewText(
                          accounts.size,
                          tags.size,
                          names.size,
                          resources.size
                        )}{' '}
                        )
                      </div>
                      <TextLink
                        color="primary"
                        onClick={() =>
                          params.node.setExpanded(!params.node.expanded)
                        }
                      >
                        Show/Hide Filters
                      </TextLink>
                    </div>
                  )
                },
              },
            }}
          />
        </BorderedCard>
        <Modal
          isOpen={this.state.createOpen}
          toggle={this.toggleCreateModal}
          size="lg"
          style={{
            maxWidth: '900px',
            width: '80vw',
          }}
        >
          <CreateEditForm
            creating
            cancel={this.closeCreateModal}
            toggle={this.toggleCreateModal}
          />
        </Modal>
        <SwimlaneHelpModal
          isOpen={this.state.helpModalOpen}
          toggle={this.closeHelpModal}
        />
      </div>
    )
  }
}

SwimlaneManager.propTypes = {
  push: PropTypes.func,
  swimlanes: ImmutablePropTypes.iterable.isRequired,
  swimlanesLoading: PropTypes.bool,
  theme: themeShape,
  sonraiUsers: ImmutablePropTypes.map.isRequired,
}

const mapStateToProps = createStructuredSelector({
  swimlanes: selectSwimlanes,
  swimlanesLoading: selectSwimlanesLoading,
  sonraiUsers: selectSonraiUsers,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({ key: 'swimlaneManager', reducer })
const withSaga = injectSaga({ key: 'swimlaneManager', saga: sagas })

export default compose(
  withReducer,
  withConnect,
  withSaga
)(themeable(SwimlaneManager))

import React, { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import DataTable from 'components/DataTable'

class SwimlanePreview extends Component {
  styles = {
    container: {
      height: '100%',
    },
  }

  getData = () => {
    // name, srn, account, tagSet, resource id (as specified by High Overlord Jon Beardsman II)
    let data = []
    if (this.props.resources) {
      this.props.resources.toJS().forEach(resource => {
        data.push({
          name: resource.name ? resource.name : '',
          account: resource.account ? resource.account : '',
          tagSet: resource.tagSet ? resource.tagSet : '',
          srn: resource.srn ? resource.srn : '',
          resourceId: resource.resourceId ? resource.resourceId : '',
        })
      })
    }
    return data
  }

  sortAlpha = (a, b) => {
    if (a.toLowerCase() === b.toLowerCase()) {
      return 0
    } else if (a.toLowerCase() > b.toLowerCase()) {
      return 1
    } else if (b.toLowerCase() > a.toLowerCase()) {
      return -1
    }

    return a.toLowerCase() > b.toLowerCase()
  }

  getColumnDef = () => {
    return {
      name: {
        headerName: 'Name',
        field: 'name',
        sort: 'asc',
        comparator: this.sortAlpha,
      },
      account: {
        headerName: 'Account',
        field: 'account',
      },
      resourceId: {
        headerName: 'Resource Id',
        field: 'resourceId',
      },
      srn: {
        headerName: 'Srn',
        field: 'srn',
      },
      tagSet: {
        headerName: 'Tags',
        field: 'tagSet',
      },
    }
  }

  render() {
    return (
      <div style={this.styles.container}>
        <DataTable
          style={{ height: '480px' }}
          data={this.getData()}
          sortColumnByType={`name`}
          hasCheckBoxes={false}
          customColumnConfig={this.getColumnDef()}
        />
      </div>
    )
  }
}

SwimlanePreview.propTypes = {
  resources: ImmutablePropTypes.list,
}

export default SwimlanePreview

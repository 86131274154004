import { createSelector } from 'reselect'
import { Map, List } from 'immutable'

/**
 * Direct selector to the swimlaneManager state domain
 */
const selectSwimlaneManagerDomain = state =>
  state.get('swimlaneManager') || Map()

export const selectPreviewResultsLoading = createSelector(
  selectSwimlaneManagerDomain,
  swimlaneDomain => swimlaneDomain.get('loadingPreviewResults')
)

export const selectPreviewResults = createSelector(
  selectSwimlaneManagerDomain,
  swimlaneDomain => swimlaneDomain.get('previewResults') || List()
)

export const selectCreatingSwimlane = createSelector(
  selectSwimlaneManagerDomain,
  swimlaneDomain => swimlaneDomain.get('creatingSwimlane')
)

export const selectSwimlanesLoading = createSelector(
  selectSwimlaneManagerDomain,
  swimlaneDomain => swimlaneDomain.get('swimlanesLoading')
)

export const selectTagValues = createSelector(
  selectSwimlaneManagerDomain,
  swimlaneDomain => swimlaneDomain.get('tagValues')
)

export const selectIsLoadingTagValues = createSelector(
  selectSwimlaneManagerDomain,
  swimlaneDomain => swimlaneDomain.get('loadingTagValues')
)

export const selectBotValues = createSelector(
  selectSwimlaneManagerDomain,
  swimlaneDomain => swimlaneDomain.get('botValues') || List()
)

export const selectError = createSelector(
  selectSwimlaneManagerDomain,
  swimlaneDomain => swimlaneDomain.get('error')
)

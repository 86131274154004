import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import Icon from 'components/Icon'
import BorderlessButton from 'components/BorderlessButton'

class SwimlaneTag extends Component {
  styles = {
    container: {
      display: 'grid',
      gridTemplateColumns: '300px 300px auto 1fr',
      alignItems: 'center',
      gridColumnGap: '1em',
    },
  }

  removeTag = () => {
    this.props.onChange({
      key: this.props.tagKey,
      value: this.props.tagValue,
      toRemove: true,
    })
  }

  removeStar = str => {
    str = str.split('')
    let rmv = str.filter(char => char !== '*')
    return rmv.join('')
  }

  render() {
    const { tagKey, tagValue } = this.props
    return (
      <div style={this.styles.container}>
        <div style={this.styles.inputContainer}>
          <Input
            disabled
            value={this.removeStar(tagKey) || 'ANY VALUE'}
            style={{ fontSize: '0.9rem' }}
          />
        </div>
        <div style={this.styles.inputContainer}>
          <Input
            disabled
            style={{ fontSize: '0.9rem' }}
            value={this.removeStar(tagValue) || 'ANY VALUE'}
          />
        </div>
        <BorderlessButton
          disabled={this.props.disabled}
          onClick={this.removeTag}
          title="Remove"
        >
          <Icon fa name="times" />
        </BorderlessButton>
      </div>
    )
  }
}

SwimlaneTag.propTypes = {
  onChange: PropTypes.func,
  tagValue: PropTypes.string,
  tagKey: PropTypes.string,
  disabled: PropTypes.bool,
}
export default SwimlaneTag

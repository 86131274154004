import React from 'react'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async-creatable'

export class AsyncTypeahead extends React.Component {
  onChange = value => {
    if (!value || value.length === 0) {
      this.props.onChange(null)
      return
    }

    this.props.onChange({
      value: value.map(valObj => valObj.value),
    })
  }

  getSelectedValues = () => {
    if (!this.props.selectedValue || this.props.selectedValue.length === 0) {
      return null
    }

    return this.props.selectedValue
  }

  optionsLoaded = () => {
    return this.props.options && this.props.options.length > 0
  }

  componentDidUpdate() {
    if (this.optionsLoaded() && this.callback) {
      this.callback(this.props.options)
      this.callback = null
    }
  }

  loadOptions = (inputValue, callback) => {
    if (this.props.loadOptions) {
      if (!this.optionsLoaded()) {
        this.props.loadOptions()
        this.callback = callback
        return
      }
    }

    callback(this.getFilteredOptions(inputValue))
  }

  getFilteredOptions = inputValue => {
    const { options } = this.props
    if (inputValue) {
      return options.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      )
    }
    return options
  }

  formatCreateLabel = inputValue => {
    return `"${inputValue}"`
  }

  render() {
    return (
      <AsyncSelect
        name={this.props.name}
        value={this.getSelectedValues()}
        onChange={this.onChange}
        isClearable
        loadOptions={this.loadOptions}
        defaultOptions
        isCreatable
        isMulti
        formatCreateLabel={this.formatCreateLabel}
        allowCreateWhileLoading={this.props.allowCreateWhileLoading}
        styles={this.props.styles}
        isDisabled={this.props.isDisabled}
        getOptionLabel={this.props.getOptionLabel}
      />
    )
  }
}

AsyncTypeahead.defaultProps = {
  style: {},
}

AsyncTypeahead.propTypes = {
  allowCreateWhileLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  selectedValue: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  loadOptions: PropTypes.func,
  styles: PropTypes.object,
  isDisabled: PropTypes.bool,
  getOptionLabel: PropTypes.func,
}

export default AsyncTypeahead

/*
 * SwimlaneManager Messages
 *
 * This contains all the text for the SwimlaneManager component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  header: {
    id: 'app.containers.SwimlaneManager.header',
    defaultMessage: 'Manage Swimlanes',
  },
  keyNoStar: {
    id: 'app.containers.SwimlaneManager.keyNoStar',
    defaultMessage:
      "Invlaid character in Tag Key '*' (please use Basic Tag Input to enter wildcard)",
  },
  valueNoStar: {
    id: 'app.containers.SwimlaneManager.v',
    defaultMessage:
      "Invlaid character in Tag Value '*' (please use Basic Tag Input to enter wildcard)",
  },
  swomboHelp: {
    id: 'app.containers.SwimlaneManager.swomboHelp',
    defaultMessage:
      'Swimlanes can be defined using a combination of accounts, resource names & filters, or tag key & value pairs. Select "+Add Swimlane" to begin.',
  },
})

import React from 'react'
import PropTypes from 'prop-types'
import Creatable from 'react-select/creatable'

const components = {
  DropdownIndicator: null,
}

export class MultiValueInput extends React.Component {
  state = {
    inputValue: '',
  }

  handleChange = value => {
    if (!value) {
      this.props.onChange([])
    }

    this.props.onChange(value.map(option => option.value))
  }

  handleInputChange = inputValue => {
    this.setState({ inputValue })
  }

  handleCommit = () => {
    const { inputValue } = this.state
    if (inputValue !== '') {
      this.props.onChange([...this.props.value, inputValue])
      this.setState({
        inputValue: '',
      })
      event.preventDefault()
    }
  }

  handleKeyDown = event => {
    const { inputValue } = this.state
    if (!inputValue) return

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        this.handleCommit()
    }
  }

  getSelectedOptions = () => {
    if (!Array.isArray(this.props.value)) {
      return []
    }

    return this.props.value.map(value => ({
      label: value,
      value,
    }))
  }

  render() {
    const { inputValue } = this.state

    return (
      <Creatable
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={this.handleChange}
        onBlur={this.handleCommit}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        placeholder="Type a value and press enter..."
        value={this.getSelectedOptions()}
        isDisabled={this.props.disabled}
      />
    )
  }
}

MultiValueInput.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default MultiValueInput
